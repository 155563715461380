<template>
  <div>
    <template>
      <div :dir="$t('clan')=='ar'?'rtl':'ltr'">
        <div class="rows">
          <div class="col-md-12">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">{{$t('AllTransactions')}}</h3>
                </div>
                <span class="add" v-if="filtered === true">
                  
                    <b-button variant="dark"
                    @click="print"
                      ><i class="fas fa-print text-white mr-2"  v-if="$t('clan')=='en'"></i
                      >
                      <i class="fas fa-print text-white ml-2"  v-else></i
                      >{{$t('Print')}}</b-button
                    >
               
                </span>
              </div>
              <div class="card-body">
                <div>
                  <TransactionsTable :filtered.sync="filtered" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
 

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import QueuesTable from "@/view/pages/Queues/QueuesTable.vue";
import TransactionsTable from '@/view/pages/AllTransactions/Table.vue'

export default {
  name: 'AllTransactions',

  components: { TransactionsTable },

  data() {
    return {
      filtered :false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "AllTransactions", route: "/admin/all-transactions" }]);
    
    //loads in layout, to reduce the delay
  },
  methods:{
    print(){
      window.print();
    }
  }
  
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}
</style>