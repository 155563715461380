<template>
  <div>
    <!--<div class="form-group">
                    <div class="input-icon input-icon-right">
                      <input
                        type="text"
                        class="form-control"
                        
                        placeholder="Search by driver name or date from / to"
                      />
                      <span 
                        ><i class="flaticon2-search-1 icon-md"></i
                      ></span>
                    </div>
                  </div>-->
                  <div class="d-flex justify-content-between">
                   

                   
                  <div style="font-weight:bold" v-if="time1 !== null">
                    {{time1[0] + ' _ ' + time1[1]}}
                  </div>
                  <div style="font-weight:bold">
                    {{driverName}}
                  </div>
                 
                <div class=" mb-4" :class="{'text-right':$t('clan')=='en','text-left':$t('clan')=='ar'}">
                   <b-button variant="dark" size="sm"
                   @click="showModal2"
                  
                      ><i class="fas fa-filter text-white mr-2"  v-if="$t('clan')=='en'"></i
                      >
                      <i class="fas fa-filter text-white ml-2"  v-else></i
                      >{{$t('Filter')}}</b-button
                    >
                </div>
                </div>
                 
                  
    <div>
      <b-table
        :items="table.items"
        :fields="$t('clan')=='en'?table.fields:tableAR.fields"
        :responsive="true"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
      >

      <template #empty>
              <div v-if="isBusy" class="text-center ">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  class="spinnerClass"
                ></b-spinner>
              </div>
              <p
                v-else

                class="text-center muted mb-0 mt-2"
                style="font-size: 13px"
              >
                {{ $t('There are no records to show') }}
              </p>
            </template>

     
        <template #cell(status)="row">
              <div class="">
            <b-badge v-if="row.item.status==='waiting'" variant="warning" style="font-size:10px">{{row.item.status}}</b-badge>
            <b-badge v-if="row.item.status==='completed'" variant="success" style="font-size:10px">{{row.item.status}}</b-badge>
            <b-badge v-if="row.item.status==='rejected'" variant="danger" style="font-size:10px">{{row.item.status}}</b-badge>
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="">
            <button @click.prevent="showModal(row.item.id)">
              <i class="fas fa-check"></i>


              {{$t('Approve')}}
            </button>
            <button
              @click.prevent="showModal2(row.item.id)"
              class="ml-5"
            >
              <i class="fas fa-ban mr-2"></i>{{$t('Reject')}}
            </button>
          </div>
        </template>


       

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

     <!-- <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="left"
        size="lg"
        class="my-0"
        @click.native="getData"
      ></b-pagination> -->

      <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr" v-if="filterd === false">
    <li class="page-item "><a class="page-link" @click="prev" >{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link"  @click="next">{{$t('Next')}}</a></li>
  </ul>
    </div>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      title="Approve Transaction"
      hide-footer
    >
      <p>
        by click on approve this transaction will be approved
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >Cancel</b-button
        >
        <b-button variant="success" @click.prevent="Approve(tempId)">Approve</b-button>
      </div>
    </b-modal>


    <b-modal
      id="modal-2"
      modal-class="modal"
      ref="my-modal2"
      title="Transaction Filter"
      size="lg"
      hide-footer
    >
      <div class="row">
                    <div class="col-sm-12 mb-2">
                      <label class="form__label">{{$t('Date')}}</label>
                      <date-picker v-model="time1" range valueType="format" class="" style="width:100%"></date-picker>
                    </div>

                    <div
                      class="form-group col-sm-12"
                      
                    >
                      <label class="form__label">{{$t('Driver')}}</label>
                      <!--<b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                       
                        v-model="driverName"
                        
                      />-->
                      <vue-select
                            name="drivers"
                            label="full_name"
                            :key="'drivers-field'"
                            :options="drivers"
                            @input="setDriver"
                            v-model="driverName"
                           
                          ></vue-select>
                      
                    </div>
                  </div>

      <div class="buttons " :class="{'text-right':$t('clan')=='en','text-left':$t('clan')=='ar'}">
        <b-button variant="white" class="mr-3" @click="hideModal2"
          >{{$t('Cancel')}}</b-button
        >
        <b-button variant="dark" @click.prevent="search()">{{$t('Filter')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

export default {
  name: "AllTransactionsTable",
  components: { DatePicker },
  props:['filtered'],
  
  async mounted() {
    this.isBusy = true
    this.filterd = false
    await this.$store.dispatch("alltransactions/loadAll").then((res) => {
        //console.log(res.data.data.last_page)
      this.totalRows = res.data.data.total;
      this.lastPage = res.data.data.last_page
      this.perPage = res.data.data.per_page
      this.isBusy = false
    });
    var res = await this.$store.dispatch("queues/fetchCreateData");
    this.drivers = res.drivers
    //console.log(this.drivers)
  },

  computed: {
    ...mapGetters({ table: "alltransactions/table" }),
    ...mapGetters({ tableAR: "alltransactions/tableAR" }),
  },
  data: function () {
    return {
      drivers:[],
      isBusy:false,
      filterd:false,
      time1:null,
      driverName:'',
      driver_id:'',
     
      approval:{
          status:'',
          transaction_id:''
      },
      rejectID:null,
      tempId: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      firstPage:1,
      lastPage:'',
      
    };
  },
  methods: {
    
    setDriver(e){
      //console.log(e)
      this.driverName = e.full_name
      this.driver_id = e.id
    },
     search(){
      //console.log(this.time1)
      this.hideModal2()
      let data = {
        time:this.time1,
        driver_id:this.driver_id
      }
       this.$store.dispatch('alltransactions/search',data).then((res)=>{
         //console.log(res)
         this.perPage = res.data.length
      //console.log(res)
      
       })
       this.filterd = true
       this.$emit('update:filtered',this.filterd)
      
      
    },
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
        
      }
      this.getData()
      
      

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      
      }
      this.getData()
      
      

    },
    async getData() {
      if(this.filterd !== true){
        this.isBusy = true
      await this.$store.dispatch("alltransactions/loadPage", this.currentPage);
      this.isBusy = false
      }
     
    },
    /*viewQueue(id) {
      this.$router.push({ name: "EditTarrifClients", params: { id: id } });
    },*/
    /*async deleteTarrif() {
      //this.tarrif.id = this.tempId;
      var tarrif = {id:this.tempId}
      await this.$store.dispatch("clientTarrif/delete", tarrif);
      await this.$store.dispatch("clientTarrif/loadAll").then((res) => {
        this.totalRows = res.data.total;
      });
      this.hideModal();
    },*/

    showModal(id) {
        //console.log(id)
      this.$refs["my-modal"].show();
      this.tempId = id;
    },
    showModal2() {
        //console.log(id)
      this.$refs["my-modal2"].show();
     
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideModal2() {
      this.$refs["my-modal2"].hide();
    },
  },
};
</script>
<style lang="css" scoped>
</style>